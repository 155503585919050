<template>
  <div>
    <b-card-code :title="$t('Manage') + ' ' + $t('Devices')">
      <!-- search input -->
      <div class="custom-search d-flex justify-content-between" style="position: relative; top: -45px;">
        <div>
          <!-- <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            v-b-modal.modal-create-device
          >
            <feather-icon icon="PlusIcon" class="mr-50" />
            <span class="align-middle">{{$t('Add')}}</span>
          </b-button> -->
        </div>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">{{$t('Search')}}</label>
            <b-form-input v-model="searchTerm" :placeholder="$t('Search')" type="text" class="d-inline-block" />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        style="position: relative; top: -45px;"
        :columns="columns"
        :isLoading="loadingDevices"
        :rows="devices"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: { field: 'device_stream_key', type: 'asc' },
        }"
      >
        <!-- Slot: Table Column -->
        <template
          slot="table-column"
          slot-scope="props"
        >
          <span
            v-if="props.column.label ==='Device ID'"
            class="text-nowrap"
          >
            {{ $t('Device') + ' ID' }}
          </span>
          <span
            v-else-if="props.column.label ==='Scene'"
            class="text-nowrap"
          >
            {{ $t('Scene') }}
          </span>
          <span
            v-else-if="props.column.label ==='Action'"
            class="text-nowrap"
          >
            {{ $t('Action') }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <template slot="table-row" slot-scope="props">
          <!-- Column: No -->
          <span v-if="props.column.field === 'index'">
            {{ props.index + 1 }}
          </span>
          <!-- Column: Scene -->
          <span v-if="props.column.field === 'scene'">
            {{ getSceneNameByDeviceId(props.row) }}
          </span>
          <!-- Column: Action -->
          <span v-if="props.column.field === 'action'" class="action-btns">
            <b-button
              :disabled="getSceneByDeviceId(props.row.device_stream_key) != null"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-success"
              v-b-modal.modal-assign-device
              size="sm"
              @click="setSelectedDevice(props.row)"
            >
              <feather-icon icon="ArrowRightCircleIcon" class="mr-50" />
              <span class="align-middle">{{ $t('Assign') }}</span>
            </b-button>

            <b-button
              :disabled="getSceneByDeviceId(props.row.device_stream_key) == null"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-danger"
              size="sm"
              @click="unassignScene(props.row)"
            >
              <feather-icon icon="ArrowLeftCircleIcon" class="mr-50" />
              <span class="align-middle">{{ $t('Unassign') }}</span>
            </b-button>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> {{$t('Showing')}} 1 {{$t('to')}} </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> {{$t('of')}} {{ props.total }} {{$t('entries')}} </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card-code>

    <!-- modal-assign-device-->
    <b-modal
      id="modal-assign-device"
      cancel-variant="outline-secondary"
      ok-title="Save"
      :cancel-title="$t('Cancel')"
      title="Assign/Unassign Scene"
      centered
      no-close-on-backdrop
      ref="modal-assign-device"
      :ok-disabled="editLoading"
      @ok="assignScene"
    >
      <validation-observer ref="editSceneRules">
        <b-form>
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Scene"
              rules="required"
            >
              <label for="scene">{{$t('Scene')}}</label>
              <v-select
                id="scenes"
                v-model="selectedScene"
                :options="unassigned_scenes"
                label="name"
                placeholder="Select scene"
                :getOptionLabel="getDeviceSceneOptionLabel"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BModal,
  BForm,
  VBModal,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import vSelect from "vue-select";
import { gmapApi } from "vue2-google-maps";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BModal,
    BForm,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      selectedScene: null,
      markers: [],
      newDevice: {},
      selectedDevice: {},
      editScene: {
        newSceneName: "",
        gpsLocation: "",
        deviceId: "",
        parkingLot: "",
      },
      createLoading: false,
      editLoading: false,
      pageLength: 10,
      dir: false,
      columns: [
        {
          label: "Device ID",
          field: "device_stream_key",
        },
        // {
        //   label: "File Name",
        //   field: "filename",
        // },
        {
          label: "Scene",
          field: "scene",
        },
        // {
        //   label: "License",
        //   field: "license_plate",
        // },
        {
          label: "Action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    selectedPrincipal() {
      return this.$store.state.global.selectedPrincipal;
    },
    selectedParkingLot() {
      return this.$store.state.global.selectedParkingLot;
    },
    devices() {
      return this.$store.state.device.devices;
    },
    loadingDevices() {
      return this.$store.state.device.loadingDevices;
    },
    principals() {
      return this.$store.state.principal.principals;
    },
    scenes() {
      return this.$store.state.scene.scenes;
    },
    unassigned_scenes() {
      const data = this.$store.state.scene.unassigned_scenes;
      // data.unshift({
      //   name: "No scene",
      //   parkingLot: {
      //     name: "It will remove the assigned scene",
      //   },
      // });
      return data;
    },
  },
  async created() {
    await this.$store.dispatch("device/get", this.$bvToast);
    await this.$store.dispatch("scene/get", {
      deviceId: "unassigned",
      bvToast: this.$bvToast,
    });
    await this.$store.dispatch("scene/get", { bvToast: this.$bvToast });
  },
  mounted() {},
  methods: {
    getDeviceSceneOptionLabel(option) {
      return `${option.name} (${option?.parkingLot?.name})`;
    },
    updateScene(unassign) {
      let scene = this.selectedScene;
      let device_stream_key = this.selectedDevice.device_stream_key;
      if (unassign) {
        scene = this.getSceneByDeviceId(this.selectedDevice.device_stream_key);
        device_stream_key = "";
      }
      if (scene == null) {
        return;
      }
      this.editLoading = true;
      this.$store
        .dispatch("scene/store", {
          deviceId: device_stream_key,
          sceneName: scene.name,
          parkingLot: scene.parkingLot?.name,
          principal: scene.parkingLot?.principal?.name,
          newParkingLot: scene.parkingLot?.name,
          newPrincipal: scene.parkingLot?.principal?.name,
        })
        .then(async (response) => {
          this.$store.commit("scene/EDIT_SCENE", response.data);
          this.editLoading = false;
          this.$refs["modal-assign-device"].hide();

          await this.$store.dispatch("scene/get", {
            deviceId: "unassigned",
            bvToast: this.$bvToast,
          });

          this.$bvToast.toast("Scene updated successfully", {
            title: `Success`,
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.editLoading = false;

          this.$bvToast.toast(err.message, {
            title: `Error occured`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-bottom-right",
          });
        });
    },
    assignScene(bvModalEvt, unassign) {
      bvModalEvt?.preventDefault();

      this.$refs.editSceneRules.validate().then((success) => {
        if (success) {
          this.updateScene();
        }
      });
    },
    unassignScene(row) {
      this.setSelectedDevice(row);
      this.$bvModal
        .msgBoxConfirm(this.$t('Are you sure to unassign scene?'), {
          title: this.$t('Please Confirm'),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            this.updateScene(true);
          }
        });
    },
    setSelectedDevice(row) {
      this.selectedScene = null;
      this.selectedDevice = {
        device_stream_key: row.device_stream_key,
        filename: row.filename,
        lastseen: row.lastseen,
        coords: row.coords,
      };
    },
    getSceneNameByDeviceId(row) {
      const found = this.scenes.find((item) => item.deviceId == row.device_stream_key);

      let result = "";
      if (found?.name) {
        result = found?.name;
      }
      if (found?.parkingLot?.name) {
        result += ` (${found?.parkingLot?.name})`;
      }
      return result;
    },
    getSceneByDeviceId(device_stream_key) {
      const found = this.scenes.find((item) => item.deviceId == device_stream_key);
      return found;
    },
    removeDevice(row) {
      this.$bvModal
        .msgBoxConfirm(this.$t('Are you sure?'), {
          title: this.$t('Please Confirm'),
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value == true) {
            const removeDevice = {
              device: row.name,
              principal: row.principal ? row.principal.name : null,
            };

            this.$store
              .dispatch("device/remove", removeDevice)
              .then(async (response) => {
                this.$bvToast.toast("Parking Lot deleted successfully", {
                  title: `Success`,
                  variant: "success",
                  solid: true,
                });

                await this.$store.dispatch("device/get", this.$bvToast);
              })
              .catch((err) => {
                this.$bvToast.toast(err.message, {
                  title: `Error occured`,
                  variant: "danger",
                  solid: true,
                  toaster: "b-toaster-bottom-right",
                });
              });
          }
        });
    },
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-select.scss";

[dir] .vs__dropdown-toggle {
  border: 1px solid #d8d6de !important;
}
.scenes-select {
  .vs__selected {
    background-color: #7367f0 !important;
    cursor: auto !important;

    .vs__deselect {
      display: none !important;
    }
  }
  .vs__search {
    cursor: auto !important;
  }
}
</style>
